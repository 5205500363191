<template>
  <div class="form_setting_template_list">
    <div v-if="type===1" class="form_category_grid">
      <div class="form_category_item form_category_item_add cursor-pointer" v-if="actionPermissionMap['systemConfig:workOrder:type:add']" @click="dialogVisible=true">
        <i class="el-icon-plus text-primary "></i>
      </div>
      <div class="form_category_item"
        v-for="i in formCategoryList"
        :key="i.id">
          <div>
            <span>
              {{i.name}}
              <i class="icon iconfont icon-bianji1 text-disable cursor-pointer ml-1" v-auth="'systemConfig:workOrder:type:edit'"   @click="handleEditName(i)"></i>
            </span>
            <!--20250114 要求改为定制工单中不显示-->
            <span v-if="configCode!=='WORK_ORDER' || i.prefabricateType!=='USER_PREFABRICATE'">
              <span :class="{'text-disable':!i.status,'text-primary':i.status,'mr-3':true}">{{i.status?'启用':'未启用'}}</span>
              <el-switch size="mini"
                :disabled="!actionPermissionMap['systemConfig:workOrder:type:use'] || i.prefabricateType==='USER_PREFABRICATE'"
                :value="i.status"
                @change="handleEnable(i,$event)">
              </el-switch>
            </span>
          </div>
          <div>
              <el-tag :type="i.prefabricateType==='INIT_PREFABRICATE'?'warning':(i.prefabricateType==='USER_PREFABRICATE'?'primary':'success')" class="mr-2" size="mini" effect="dark">{{formTypeMap[i.prefabricateType]}}</el-tag>
              <el-tag :type="i.useFlag?'primary':'info'" effect="plain"  size="mini">{{i.useFlag?'已使用':'未使用'}}</el-tag>
          </div>
          <div :title="i.customizeBusinessScenarioName">
            <span>业务场景：{{i.customizeBusinessScenarioName}}</span>
          </div>
          <div>
            <span>最近更新：{{i.updateUserName}} {{i.lastUpdateTime | date-format}}</span>
          </div>
          <div>
            <span>
              <!--20250114 要求改为定制工单也显示打印配置和表单配置-->
              <el-tooltip  effect="dark" content="打印配置" placement="top">
              <i class="icon iconfont icon-dayinpeizhi text-primary cursor-pointer" v-if="print && i.prefabricateType!=='USER_PREFABRICATE'"  @click="handleEditPrintTemplate(i)"></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="i.prefabricateType === 'USER_PREFABRICATE' ? '表单查看' : '表单配置'" placement="top">
              <i class="icon iconfont icon-biaodanpeizhi ml-2  text-primary cursor-pointer" v-auth="'systemConfig:workOrder:type:edit'" v-if="configCode==='WORK_ORDER' || i.prefabricateType!=='USER_PREFABRICATE'" @click="handleEdit(i)"></i>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top">
              <i  class="icon iconfont icon-shanchu ml-2 text-danger cursor-pointer" v-auth="'systemConfig:workOrder:type:del'"  v-if="i.prefabricateType==='USER_CUSTOMIZE' && !i.useFlag && !i.status" @click="handleDelete(i)"></i>
              </el-tooltip>
            </span>
          </div>
        </div>
    </div>
    <div v-else class="form_category_list">
      <div>
        <div class="form_category_item"
          v-for="i in formCategoryList"
          :key="i.id">
            <div>
              <span>
                <span>
                  {{i.name}}
                  <i class="icon iconfont icon-bianji1  text-disable cursor-pointer ml-1" v-auth="'systemConfig:workOrder:type:edit'"   @click="handleEditName(i)"></i>
                </span>
                <el-tag :type="i.prefabricateType==='INIT_PREFABRICATE'?'warning':(i.prefabricateType==='USER_PREFABRICATE'?'primary':'success')" class="ml-2 mr-2" size="mini" effect="dark">{{formTypeMap[i.prefabricateType]}}</el-tag>
                <el-tag :type="i.useFlag?'primary':'info'" effect="plain"  size="mini">{{i.useFlag?'已使用':'未使用'}}</el-tag>
              </span>
              <span>
                业务场景：{{i.customizeBusinessScenarioName}}
              </span>
            </div>
            <div>
              <span>
                <span :class="{'text-disable':!i.status,'text-primary':i.status,'mr-3':true}">{{i.status?'启用':'未启用'}}</span>
                <el-switch size="mini"
                  :disabled="!actionPermissionMap['systemConfig:workOrder:type:use']  || i.prefabricateType==='USER_PREFABRICATE'"
                  :value="i.status"
                  @change="handleEnable(i,$event)">
                </el-switch>
              </span>
              最近更新：{{i.updateUserName}} {{i.lastUpdateTime| date-format}}
            </div>
            <div>
              <span>
                <el-link type="primary"  v-auth="'systemConfig:workOrder:type:edit'"  v-if="i.prefabricateType!=='USER_PREFABRICATE'"  @click.native="handleEdit(i)">表单配置</el-link>
                <el-link type="primary"  class="ml-2" v-if="print && i.prefabricateType!=='USER_PREFABRICATE'"  @click.native="handleEditPrintTemplate(i)">打印配置</el-link>
                <el-link type="danger" v-auth="'systemConfig:workOrder:type:del'"  v-if="i.prefabricateType==='USER_CUSTOMIZE' && !i.useFlag && !i.status" class="ml-2" @click.native="handleDelete(i)">删除</el-link>
              </span>
            </div>
        </div>
      </div>
      <div class="form_category_item_add" v-if="actionPermissionMap['systemConfig:workOrder:type:add']">
        <div class="cursor-pointer" @click="dialogVisible=true">
          <i class="el-icon-plus text-primary " >新增</i>
        </div>
      </div>
    </div>
    <el-dialog
      :title="`${templateForm && templateForm.code ?'修改':'新增'}${configName}${templateForm && templateForm.code ?'名称':''}`"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="460px"
      @close="handleDialogClose">
      <el-form :model="templateForm" :rules="templateFormRules" ref="templateForm" label-width="100px">
        <el-form-item label="类型名称" prop="name">
          <el-input v-model="templateForm.name" placeholder="请填写类型名称"></el-input>
        </el-form-item>
        <el-form-item label="业务场景" prop="customizeBusinessScenarioId" v-show="!templateForm || !templateForm.code ">
          <el-select v-model="templateForm.customizeBusinessScenarioId"
            placeholder="请选择业务场景"
            @change="handleBusinessTypeChanged">
            <el-option v-for="i in businessTypeList"
              :key="i.id"
              :label="i.name"
              :value="i.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveNewTemplate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const formTypeMap = {
  'INIT_PREFABRICATE': '预制',
  'USER_CUSTOMIZE': '用户',
  'USER_PREFABRICATE': '定制',
};
import {
  getFormTypeListByConfigId,
  deleteFormTemplate,
  enabelFormTemplate,
  getConfigBusinessType,
  saveNewFormTemplate,
  updateTemplate,
} from '@/api/formSetting';
import { mapGetters } from 'vuex';

export default {
  name: 'form_setting_template_list',
  props: {
    type: {
      type: Number,
      default: 1,
    },
    configId: String,
    configCode: String,
    configName: String,
    print: Boolean,
    isNewFlow: Boolean,
  },
  data () {
    return {
      formTypeMap,
      formCategoryList: [],
      dialogVisible: false,
      templateForm: {
        code: null,
        name: '',
        customizeBusinessScenarioId: '',
        customizeBusinessScenarioName: '',
      },
      templateFormRules: {
        name: [
          { message: '请填写类型名称', required: true, trigger: ['blur', 'change']},
          { message: '类型名称最大10个字符', max: 10, trigger: ['blur', 'change']},
        ],
        customizeBusinessScenarioId: [
          { message: '请选择业务场景', required: true, trigger: ['blur', 'change']},
        ],
      },
      businessTypeList: [],
    };
  },
  computed: {
    ...mapGetters([
      'actionPermissionMap',
    ]),
  },
  methods: {
    handleEditName (i) {
      let {name, code, customizeBusinessScenarioId, customizeBusinessScenarioName} = i;
      this.templateForm = {name, code, customizeBusinessScenarioId, customizeBusinessScenarioName};
      this.dialogVisible = true;
    },
    handleDisaplyTabChanged (tabIndex) {
      this.activeTab = tabIndex;
      this.getFormCategoryList();
    },
    handleBusinessTypeChanged (businessTypeId) {
      let busineeeType = this.businessTypeList.find(t=>t.id === businessTypeId);
      this.templateForm.customizeBusinessScenarioName = busineeeType?.name;
    },
    handleDialogClose () {
      this.$refs.templateForm.resetFields();
      this.templateForm = {
        code: null,
        name: '',
        customizeBusinessScenarioId: '',
        customizeBusinessScenarioName: '',
      };
    },
    handleSaveNewTemplate () {
      this.$refs.templateForm.validate(valid=>{
        if (valid) {
          let action = null;
          if (this.templateForm.code) {
            action = updateTemplate({...this.templateForm});
          } else {
            action = saveNewFormTemplate({
              ...this.templateForm,
              prefabricateFlag: 'USER_CUSTOMIZE',
              customizeSystemConfigId: this.configId,
              customizeSystemConfigCode: this.configCode,
              customizeSystemConfigName: this.configName,
            });
          }
          action.then(()=>{
            this.dialogVisible = false;
            this.getFormCategoryList();
          });
        }
      });
    },
    handleEdit (i) {
      if (this.isNewFlow) {
        window.open(`${location.origin}${process.env.BASE_URL}designer.html#/formSetting?code=${i.code}`);
      } else {
        const isUserPrefabricatedWorkOrder = this.configCode === 'WORK_ORDER' && i.prefabricateType === 'USER_PREFABRICATE' ? '1' : '0';

        this.$router.push({
          name: 'FORM_SETTING',
          query: {
            code: i.code,
            isUserPrefabricatedWorkOrder,
          },
        });
      }
    },
    handleEditPrintTemplate (i) {
      const isUserPrefabricatedWorkOrder = this.configCode === 'WORK_ORDER' && i.prefabricateType === 'USER_PREFABRICATE' ? '1' : '0';
      this.$router.push({
        name: 'FORM_PRINT_TEMPLATE',
        query: {
          templateCode: i.code,
          templateName: i.name,
          isUserPrefabricatedWorkOrder,
        },
      });
    },
    handleDelete (i) {
      this.$confirm(`即将删除${this.configName} "${i.name}"，是否确认？`, `删除${this.configName}`, {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
      }).then(()=>{
        deleteFormTemplate(i.id).then(()=>{
          this.getFormCategoryList();
        });
      });
    },
    handleEnable (i, value) {
      enabelFormTemplate({id: i.id, status: value}).then(()=>{
        this.getFormCategoryList();
      });
    },
    getBusinessTypeList () {
      getConfigBusinessType(this.configCode).then(res=>{
        this.businessTypeList = res.body;
      });
    },
    getFormCategoryList () {
      getFormTypeListByConfigId({customizeSystemConfigCode: this.configCode}).then(ret=>{
        this.formCategoryList = ret.body;
      });
    },
  },
  watch: {
    configCode () {
      if (this.configCode) {
        this.getBusinessTypeList();
        this.getFormCategoryList();
      }
    },
  },
  created () {
    if (this.configCode) {
      this.getBusinessTypeList();
      this.getFormCategoryList();
    }
  },
};
</script>
<style lang="scss" scoped>
.form_setting_template_list {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  overflow-y: auto;

  .form_category_grid {
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 314px);
    grid-template-rows: repeat(auto-fill, 161px);
    grid-gap: 16px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .form_category_item {
      position: relative;
      font-size: 14px;
      line-height: 1;
      box-sizing: border-box;
      width: 332px;
      height: 161px;
      padding:19px 20px 22px;
      box-shadow: 0px 2px 4px 0px rgba(36,40,50,0.1000);
      border-radius: 4px;
      border: 1px solid rgba(113,121,141,0.0800);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition-duration: 0.3s;
      transition-property: transform;

      .iconfont{
        font-size: 14px;
      }

      &:hover {
        transform: scale(1.05);
      }

      &.form_category_item_add{
        justify-content: center;
        align-items: center;
        font-size: 36px;
      }
      &>div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

          &:nth-child(1){
            &>span:first-child{
              font-weight: bolder;
              color: #242832;
            };
            justify-content: space-between;

            /deep/ .el-switch {
              height: 14px;
              line-height: 14px;

              .el-switch__core {
                height: 14px;
                width: 32px!important;

                &:after {
                  height: 12px;
                  width: 12px;
                  top: 0;
                }
              }

              &.is-checked {
                .el-switch__core:after{
                  left:auto;
                  right: 0;
                }
              }
            }
          }

          &:nth-child(2){
            padding-top: 12px;
            padding-bottom: 15px;

            /deep/ .el-tag  {
              border-radius: 2px;
            }
          }
          &:nth-child(3),&:nth-child(4) {
            font-weight: 400;
            color: rgba(36,40,50,0.7000);
            &>span{
              text-overflow:ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          &:nth-child(4){
            padding-top: 15px;
            padding-bottom: 12px;
          }

          &:nth-child(5){
            position: absolute;
            right: 30px;
            bottom: 10px;
            justify-content: flex-end;
            font-size: 16px;
          }
      }
    }
  }

  .form_category_list {
    box-shadow: 0px 2px 4px 0px rgba(36,40,50,0.1000);
    border-radius: 4px;
    border: 1px solid rgba(113,121,141,0.0800);
    padding: 0 12px;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &>div {
      &:first-child{
        flex-shrink: 1;
        overflow-x: hidden;
        overflow-y: auto;
        .form_category_item{
          font-size: 14px;
          line-height: 1;
          font-weight: 400;
          color: rgba(43,38,48,0.6000);
          box-sizing: border-box;
          height: 85px;
          padding: 19px 0;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid  rgba(36,40,50,0.1600);
          border-radius: 1px;
          &:hover{
            // transform: translateX(12px);
            color: $primary-color;
          }

          .iconfont{
            font-size: 14px;
          }

          &>div:nth-child(1){
            width: 320px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &>span:nth-child(1){
              display: flex;
              flex-direction: row;
              align-items: center;
              &>span:first-child {
                font-weight: bolder;
              }
              color: #242832;
              /deep/ .el-tag {
                border-radius: 2px;
              }
            }
          }

          &>div:nth-child(2){
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            /deep/ .el-switch {
              height: 14px;
              line-height: 14px;

              .el-switch__core {
                height: 14px;
                width: 32px!important;

                &:after {
                  height: 12px;
                  width: 12px;
                  top: 0;
                }
              }

              &.is-checked {
                .el-switch__core:after{
                  left:auto;
                  right: 0;
                }
              }
            }
          }

          &>div:nth-child(3){
            width: 132px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }
        }
      }

      &.form_category_item_add {
        flex-grow: 1;
        display: flex;
        align-items:flex-start;
        height: 46px;
        line-height: 46px;
        width: 100%;
        background: white;
        &>div{
          width: 100%;
          text-align: center;
          border-bottom: 1px solid  rgba(36,40,50,0.1600);
        }
      }
    }

  }
}
</style>
